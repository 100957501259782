import DemoPageOptions from "@/models/DemoPageOptions";
import * as Getters from "@/store/constants/getters";
import * as Mutations from "@/store/constants/mutations";
import * as Actions from "@/store/constants/actions";
import { ActionContext } from "vuex";
import { IRootState } from "@/store";
import { HttpRequestDispatcher, HttpRequestMethod } from "@/utilities/HttpRequestDispatcher";
import TokenizationData from "@/models/TokenizationData";

export interface IDemoPageState {
    options: DemoPageOptions | null;
}

export const DemoPageModule = {
    state: {
        options: null,
    } as IDemoPageState,
    getters: {
        [Getters.GET_DEMO_OPTIONS]: (state: IDemoPageState): DemoPageOptions => state.options!,
    },
    mutations: {
        [Mutations.SET_DEMO_OPTIONS](state: IDemoPageState, options: DemoPageOptions): void {
            state.options = options;
        },
    },
    actions: {
        [Actions.LOAD_DEMO_OPTIONS](
            context: ActionContext<IDemoPageState, IRootState>
        ): Promise<void> {
            const requestURL = "/api/Options";

            return HttpRequestDispatcher.SendRequest(HttpRequestMethod.GET, requestURL);
        },
        [Actions.MAKE_TOKENIZATION_URL](
            context: ActionContext<IDemoPageState, IRootState>,
            payload: TokenizationData
        ): Promise<void> {
            const requestURL = "/api/tokenization/getUrl";

            return HttpRequestDispatcher.SendRequest(HttpRequestMethod.POST, requestURL, payload);
        },
    },
};

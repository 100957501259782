import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
    {
        path: "/",
        name: "home",
        component: () => import("../views/HomePage/HomePage.vue"),
    },
    {
        path: "/Sandbox",
        name: "sandbox",
        component: () => import("../views/SandboxPage.vue"),
    },
    {
        path: "/Fail",
        name: "payment-fail",
        component: () => import("../views/PaymentFailPage.vue"),
    },
    {
        path: "/3ds",
        name: "3DS",
        component: () => import("../views/ThreeDsConfirm.vue"),
    },
    {
        path: "/:catchAll(.*)",
        name: "not-found",
        component: () => import("../views/NotFoundPage.vue"),
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;

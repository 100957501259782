import { createStore } from "vuex";
import { DemoPageModule } from "@/store/modules/DemoPageModule";

export interface IRootState {}

export default createStore({
    state: {} as IRootState,
    getters: {},
    mutations: {},
    actions: {},
    modules: {
        DemoPageModule,
    },
});
